@import 'colors.scss';

html {
  background-color: #fafafa;
}

html,
body {
  font-family: 'Avenir', 'Helvetica', sans-serif;
  font-size: 16px;
  margin: 0;
}

.container {
  display: block;
  max-width: 1100px;
  margin: 96px auto;

  p {
    max-width: 90%;
    margin: 0 auto;
  }
}

.wide-container {
  width: 80%;
  margin: 100px auto;
}

/* Sections */

#hero {
  margin: 100px auto 100px auto;

  @media (max-width: 500px) {
    margin: 100px auto 50px auto;
  }

  text-align: center;
  h2 {
    padding: 0 1em;
    font-size: 1.7em;
  }
}

/* Common Elements */

button,
.button {
  //margin-left: 1em;
  font-family: 'Avenir', 'Helvetica', sans-serif;
  font-size: 1.2em;
  display: inline-block;
  position: relative;
  top: 0px;
  transition: all 0.1s linear;
  background-color: $primary-color;
  color: #fff;
  font-weight: 600;
  padding: 10px 15px;
  border-radius: 3px;
  cursor: pointer;
  border: none;
}

button.secondary,
.button.secondary {
  color: #666;
  /* color: $primary-color; */
  border: 1px solid #666;
  background-color: #fff;
}

button:hover,
.button:hover {
  top: -2px;
  background-color: $hover-color;
  color: #fff;
}

button.secondary:hover,
.button.secondary:hover {
  border: 1px solid $primary-color;
  background-color: #fff;
  color: $primary-color;
}

a {
  color: $primary-color;
  font-weight: 600;
  text-decoration: none;
}

a:hover {
  color: $hover-color;
  text-decoration: none;
}

p,
ul,
ol {
  font-size: 1rem;
  line-height: 170%;
}

h1 {
  padding: 0 1em;
  margin: 0 auto .5em;;
  max-width: 750px;
  color: #444;
  font-weight: 800;
  font-size: 60px;
  line-height: 125%;
  text-align: center;
}

@media (min-width: 600px) and (max-width: 950px) {
  h1 {
    font-size: 48px;
  }
}

@media (max-width: 600px) {
  h1 {
    font-size: 40px;
    line-height: 135%;
    margin-bottom: 0.5em;
  }
}

h2 {
  margin: 0 auto;
  max-width: 780px;
  color: #444;
  font-weight: 800;
  font-size: 36px;
  line-height: 60px;
  text-align: center;
}

@media (max-width: 950px) {
  h2 {
    font-size: 32px;
    line-height: 40px;
  }
}

@media (max-width: 600px) {
  h2 {
    padding: 0 1em;
    font-size: 20px;
    line-height: 42px;
  }
}

h3 {
  color: $primary-color;
  font-size: 24px;
  font-weight: 800;
  margin: 0px 0 0px 0;
  /* border-bottom: 4px solid $primary-color; */
}

@media (max-width: 950px) {
  h3 {
    padding: 0 1em 0 1em;
    display: inline-block;
  }
}

.nowrap {
  white-space: nowrap;
}

.centered {
  text-align: center;
}


.bubble-message {
  display: block;
  line-height: 32px;
  padding: 0 20px;
  //font-size: 15px;
  color: $grey-color;
  border: 1px solid #E9E9E9;
  border-radius: 18px;
  text-align: center;
  margin: 10px auto 20px;
  width: 320px;

  span {
    color: $danger-color
  }

  &:hover {
    color: $hover-color;
  }
}

.call-to-action {
  margin-top: 2em;
  margin-bottom: .5em;
}
